import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import Layout from "../components/Layout/layout"
import Seo from "../components/Seo/seo"
import Map from "../components/Map/map"
import ImageTextBlock from "../components/ImageTextBlock/imageTextBlock"
import { main } from "../styles/theme"

const HeadingWrapper = styled.div`
  margin: 46px 0 0;

  @media ${main.breakpoints.large} {
    margin: 196px 0 0;
  }
`

const CustomH1 = styled.h1`
  font-size: 23vw;
  line-height: 0.5;
  letter-spacing: -0.02em;
  font-weight: normal;
  margin: 0;
  white-space: break-spaces;

  @media ${main.breakpoints.small} {
    font-size: 24.8vw;
    line-height: 0.6;
  }

  @media ${main.breakpoints.xlarge} {
    font-size: 25.5vw;
    line-height: 0.7;
  }
`

const Omradet = () => (
  <Layout>
    <Seo title="Området" />
    <HeadingWrapper>
      <CustomH1>Området</CustomH1>
    </HeadingWrapper>
    <StaticImage
      src="../images/omrade.jpg"
      quality={95}
      layout="fullWidth"
      formats={["AUTO", "WEBP"]}
      alt="Om Smedjan och 4D Gruppen"
      style={{ marginTop: `2.45rem` }}
    />
    <ImageTextBlock
      preHeading="Bo i Europas coolaste område"
      text={[
        `I Göteborg är Smedjans kvarter redan kända för sitt levande kultur-, bar-, restaurang-, butiks- och klubbliv. Ändå blev nog en och annan lite förvånad när tidningen The Guardian utnämnde Järntorget och Långgatorna till det i särklass coolaste området i hela Europa. The Guardian tipsade om bra skivaffärer, Pusterviks livemusik och klubbkvällar samt Folkteatern. Bra mat hittade de på Tacos & Tequila och Kafé Magasinet. Soho Beer House eller Dugges (Brewers Beer Bar) fick bra omdöme för sin öl.`,
      ]}
      buttonTitle="Läs hela artikeln här"
      externalLink="https://www.theguardian.com/travel/2020/feb/08/10-of-the-coolest-neighbourhoods-in-europe-paris-berlin-rome?fbclid=IwAR0ExA4PEYQ-zofhoWFt7tecIsz3v3DIIA87IjR6yEKWRV3aHJrKfUDVSec"
      imagePosition="left"
      offset={1}
      columns={6}
    />
    <ImageTextBlock
      preHeading="Det nya kvarteret Barken"
      text={[
        `Kvarteret Barken ligger, enkelt förklarat, mellan Tredje och Fjärde långgatan. Här kommer det de närmaste åren bland annat byggas hotell i det gamla polishuset. En passage kommer att skapas för att förbinda de två gatorna och ett nytt torg kommer att anläggas i närheten av Smedjan. Torget kommer att skapas med traditionell gatsten i påfågelsmönster. Detta kommer att fylla redan levande kvarter med ännu mer liv och rörelse. Det planeras bland annat för mer grönska samt fler barer, restauranger och butiker.`,
      ]}
      imagePosition="right"
      offset={2}
      columns={4}
      image={
        <StaticImage
          src="../images/kvarteret-barken.jpg"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="Kvarteret Barken"
          placeholder="blurred"
          layout="fullWidth"
        />
      }
    />
    <ImageTextBlock
      preHeading="Bo i ett av Göteborgs populäraste områden"
      text={[
        `Har man en gång flyttat till Linnéstaden blir man lätt kvar här och det är inte undra på. Få stadsdelar erbjuder kombinationen av kontinental stadsvibb, ståtliga sekelskifteshus och en park som Slottsskogen intill, nästan som en egen trädgård. Alltihop inlindat i en avslappnad och tillbakalutad atmosfär.`,
      ]}
      imagePosition="left"
      offset={2}
      columns={5}
      image={
        <StaticImage
          src="../images/tredjelanggatan_1.jpg"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="Tredje långgatan"
          placeholder="blurred"
          layout="fullWidth"
        />
      }
    />
    <ImageTextBlock
      preHeading="Hemmakänsla ute"
      text={[
        `Linnéstaden är en av Göteborgs mest omtyckta stadsdelar. Oavsett om du bor här eller bara brukar hänga på någon av de många restaurangerna, barerna eller kaféerna är det lätt hänt att du gör Linnéstaden till din hemmaplan. Det som bygger upp den sköna stämningen i Linnéstaden är förmodligen områdets blandning av folk, allt ifrån unga hipsters till äldre damer med hund och småbarn i färgglada overaller. Längs huvudstråket Linnégatan är det alltid folk i rörelse och gatan binder ihop Järntorget i norr med Slottsskogen i söder, samt sidogatorna med dess respektive kvarter.`,
      ]}
      imagePosition="right"
      offset={2}
      columns={4}
      image={
        <StaticImage
          src="../images/tredjelanggatan_2.jpg"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="Tredje långgatan"
          placeholder="blurred"
          layout="fullWidth"
        />
      }
    />
    <ImageTextBlock
      preHeading="Slottsskogen är din trädgård"
      text={[
        `När du bott ett tag i Linnéstaden upptäcker du snart att de olika kvarteren skiljer sig åt i såväl karaktär som byggnadsstilar. Linnégatan kantas av många stenhus från sekelskifte till 1930-tal med restauranger, kaféer, frisörer och boutiquer i gatuplan. Även i Kommendantsängen öster om Linnégatan finns många bra matställen, bagerier och speciella affärer. Olivedal, väster om Linnégatan är även det ett vibrerande område men med lugnare gator insprängda, samt några av stans allra brantaste backar och trappor. På kvällarna flockas nöjeslystna människor i Linnéstaden, framförallt i kvarteren längs Långgatorna som fått ett enormt uppsving.`,
        `Närheten till Slottsskogen är en stor orsak till att det är så populärt att bo här. Vad kan ge mer livskvalitet än att vilken dag som helst på året kunna ladda med en morgonjogg i din egen park, eller slappa i eftermiddagssolen med vännerna? Löprundan förlänger du lätt genom att ta dig vidare upp mot vildmarken i Änggårdsbergen.`,
      ]}
      imagePosition="left"
      offset={2}
      columns={5}
      image={
        <StaticImage
          src="../images/slottskogen.jpg"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="Slottskogen"
          placeholder="blurred"
          layout="fullWidth"
        />
      }
    />
    <ImageTextBlock
      preHeading="Stammisar och nytänkare"
      text={[
        `Även om Linnéområdet utvecklas och nya ställen poppar upp finns det vissa saker som ändå är precis som de alltid har varit. Visst säger det en del om en inrättningspopularitet när den har 10, 20 eller 30 år på nacken, vilket gäller för många klassiska kaféer, restauranger och butiker längs Linnégatan. Kanske är det så att invånarna i Linné ofta väljer sitt eget stammisställe, gärna i samma byggnad man bor i, så att man kan traska ner en helt vanlig tisdag och låtsas som att det är helg.`,
      ]}
      imagePosition="right"
      offset={2}
      columns={4}
      image={
        <StaticImage
          src="../images/tredjelanggatan_4.jpg"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="Tredje långgatan"
          placeholder="blurred"
          layout="fullWidth"
        />
      }
    />
    <Map />
  </Layout>
)

export default Omradet
